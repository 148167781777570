import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Header from './header';
import WhatsappButton from './WhatsappButton';

const GlobalStyle = createGlobalStyle`
  body {
    background: #211f20;
    color: #fafafa;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Footer = styled.footer`
  max-width: 600px;
  margin: 20px auto 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);

  hr {
    border-top: 3px double #8c8b8b;
    background: transparent;
    margin-bottom: 50px;
  }

  a {
    text-decoration: underline;
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
        <Footer>
          <hr />

          <p>
            © {new Date().getFullYear()} - {data.site.siteMetadata.title}
          </p>

          <p>
            Cardápio desenvolvido por{' '}
            <a href="https://sitecardapio.com.br">Site Cardápio</a>
          </p>
        </Footer>
        <WhatsappButton />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
